@font-face {
    font-family: 'AdelleSansRegular';
    src: local('AdelleSansRegular'), url('/fonts/AdelleSans-Regular.ttf') format('truetype'), url('/fonts/AdelleSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AdelleSansBold';
    src: local('AdelleSansBold'), url('/fonts/AdelleSans-Bold.ttf') format('truetype'), url('/fonts/AdelleSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
